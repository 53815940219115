<template>
    <f7-list-item
        v-show="show"
        ref="smartSelect"
        label="Obiekt noclegowy"
        placeholder="Wybierz obiekt"
        class="accommodation-select"
        smart-select
        :smart-select-params="options"
    >
        <div class="item-title item-label">Obiekt noclegowy</div>
        <select v-model="accommodationId">
            <option v-for="a in accommodations" :value="a.id" :key="a.id">
                {{nameForList(a)}}
            </option>
        </select>
    </f7-list-item>
</template>

<script>

export default {
    props: {
        alwaysVisible: Boolean,
    },
    data() {
        return {
            options: {
                openIn: 'popup',
                searchbar: true, //accommodations.length>10,
                searchbarPlaceholder: 'Wybierz obiekt',
                popupCloseLinkText: 'Zamknij',
                searchbarDisableText: 'Anuluj',
                closeOnSelect: true,
                renderItem: this.renderItem
            }
        }
    },
    computed: {
        show() {
            return this.accommodations && (this.alwaysVisible || this.manyAccommodations)
        },
        accommodationId: {
            get() {
                // hack for v-model + smart-select bug in f7
                if(this.$refs.smartSelect) {
                    this.$refs.smartSelect.f7SmartSelect.setValue(this.accommodation.id);
                }
                return this.accommodation.id;
            },
            set(id) {
                this.$store.commit('accommodation', this.accommodationById(id))
            }
        },
        accommodation() {
            return this.$store.getters.accommodation;
        },
        accommodations() {
            return this.$store.getters.accommodations;
        },
        manyAccommodations: function () {
            return this.accommodations.length>1;
        },
        sameNameAccommodations() {
            if(false===this.manyAccommodations) {
                return false;
            }
            const names = this.accommodations.map((accommodation) => accommodation.name);
            const set = new Set(names); // removes duplicates
            return names.length!==set.size;
        }
    },
    methods: {
        accommodationById(id) {
            return this.accommodations.find(accommodation => accommodation.id === id);
        },
        nameForList(accommodation) {
            let name = accommodation.name;
            if(this.sameNameAccommodations) {
                name = name + ' (' + accommodation.streetAndNumbers + ')';
            }
            return name;
        },
        renderItem(item, index) {
            const accommodation = this.accommodationById(item.value);
            const address = accommodation.address;
            return `
                    <li class="${item.className || ''}">
                      <label class="item-${item.inputType} item-content">
                        <input type="${item.inputType}" name="${item.inputName}" value="${item.value}" ${item.selected ? 'checked' : ''}/>
                        <i class="icon icon-${item.inputType}"></i>
                        <div class="item-inner">
                          <div class="item-title">${accommodation.name}</div>
                          <div class="item-after">${address.street} ${address.houseNo} ${address.flatNo ? '/' + address.flatNo : ''} </div>
                        </div>
                      </label>
                    </li>
                `;
        }
    }
}
</script>

<style>
.accommodation-select .item-inner {
    display: block !important;
}

.accommodation-select .smart-select .item-after {
    max-width: 100%;
    padding-left: 0;
    padding-top: 10px;
}

.accommodation-select .item-inner:before {
    /*
    display: none !important;
    content: '';
    */
}

</style>
