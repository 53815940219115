<template>

    <form @submit="onSubmit">
        <f7-block-header>{{formHeader}}</f7-block-header>
        <f7-list inset no-hairlines-md>
            <ul>
                <accommodation-select :always-visible="false"></accommodation-select>
                <f7-list-input label="Tryb rejestracji" type="select"
                    v-show="accommodation.requiredRefusal.length"
                    @input="form.mode = $event.target.value"
                    :value="form.mode"
                >
                    <option value="stay">Pobieranie opłaty</option>
                    <option value="refusal">Odmowa uiszczenia opłaty</option>
                </f7-list-input>
                <f7-list-input
                    :label="datepickerLabel"
                    type="datepicker"
                    placeholder="Wybierz daty"
                    :calendar-params="{dateFormat: 'dd.mm.yyyy', rangePicker: true, closeOnSelect: true, rangePickerMinDays: 2}"
                    :value="dates"
                    @calendar:change="dates = $event"
                    :error-message="datepickerErrorMessage"
                    :error-message-force="datesNotValidAndFormTouched"
                ></f7-list-input>
            </ul>
        </f7-list>


        <div v-if="tiers.length">
            <guests-one-person v-if="fromOnePersonPerRoom" :tiers="tiers" :required="required" @input="form.guests = $event.guests" ref="guestsOnePerson"></guests-one-person>
            <guests-every-person v-if="fromEveryPerson" :tiers="tiers" :required="required" @input="form.guests = $event.guests" ref="guestsEveryPerson"></guests-every-person>
            <guests-front-desk-app v-if="byFrontDeskApp" :tiers="tiers" :stay-id="form.stayId" @input="form = {...form, ...$event.form}" ref="guestsFrontDeskApp"></guests-front-desk-app>

            <f7-block-header>Komentarze</f7-block-header>
            <f7-list inset no-hairlines-md>
                <f7-list-input
                    type="textarea"
                    placeholder="Uwagi..."
                    :value="form.comments"
                    @input="form.comments = $event.target.value"
                ></f7-list-input>
            </f7-list>

            <f7-list inset>
                <f7-button raised large fill type="submit" :color="submitColor" @click="datePickerTouched = true">{{submitLabel}}</f7-button>
            </f7-list>
        </div>
        <f7-block v-else>
            Pobór opłaty w obiekcie <strong>{{accommodation.name}}</strong> jest niedostępny.
            W razie wątpliwości prosimy o kontakt z Urzędem.
        </f7-block>


    </form>
</template>

<script>
import uuid4 from 'uuid/v4'
import guestsEveryPerson from './guests-every-person.vue';
import guestsOnePerson from './guests-one-person.vue';
import guestsFrontDeskApp from './guests-front-desk-app';
import accommodationSelect from './accommodation-select';
import moment from 'moment';

export default {
    data() {
        return {
            dates: [],
            form: {
                mode: 'stay',
                stayId: uuid4(),
                paymentId: uuid4(),
                accommodationId: null,
                from: null,
                to: null,
                guests: [],
                otherGuests: [],
                guestsPerTier: [],
                collectedAmount: null,
                comments: null
            },
            datePickerTouched: false
        }
    },
    computed: {
        datesNotValid() {
            return ! (!!this.dates && this.dates.length===2);
        },
        datesNotValidAndFormTouched() {
            return this.datesNotValid && this.datePickerTouched;
        },
        accommodation() {
            return this.$store.getters.accommodation;
        },
        accommodations() {
            return this.$store.getters.accommodations;
        },
        tiers() {
            return this.accommodation.tiers;
        },
        required() {
            return this.refusalMode ? this.accommodation.requiredRefusal : this.accommodation.required;
        },
        refusalMode() {
            return this.form.mode==='refusal'
        },
        fromOnePersonPerRoom() {
            return this.refusalMode ? false : this.accommodation.mode === 'one_person_per_room';
        },
        fromEveryPerson() {
            return this.refusalMode ? true : this.accommodation.mode === 'every_person';
        },
        byFrontDeskApp() {
            return this.refusalMode ? false : this.accommodation.mode === 'front_desk_app';
        },
        formHeader() {
            return this.refusalMode || !this.byFrontDeskApp ? 'Pobyt' : 'Okres';
        },
        datepickerLabel() {
            return this.refusalMode || !this.byFrontDeskApp ? 'Planowany okres' : 'Okres rejestrowanych wpłat';
        },
        datepickerErrorMessage() {
            return this.refusalMode || !this.byFrontDeskApp ? 'Podaj okres pobytu' : 'Podaj okres';
        },
        submitLabel() {
            return this.refusalMode ? 'Rejestruj odmowę' : (this.byFrontDeskApp ? 'Zarejestruj' : 'Rejestruj pobyt');
        },
        submitColor() {
            return this.refusalMode ? 'red' : 'blue';
        }
    },
    watch: {
        dates(dates) {
            if(dates[0]) {
                this.form.from = moment(dates[0]).format('YYYY-MM-DD');
            }
            if(dates[1]) {
                this.form.to = moment(dates[1]).format('YYYY-MM-DD');
            }
        }
    },
    mounted() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === "accommodation") {
                this.form.mode = 'stay';
            }
        });
    },
    methods: {
        formData() {
            const formData = new FormData();
            this.buildFormData(formData, this.form);
            return formData;
        },
        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        },
        onSubmit(evt) {
            evt.preventDefault();

            this.form.accommodationId = this.accommodation.id

            if(this.datesNotValid) {
                this.$f7.dialog.alert('Podaj datę pobytu');
                return;
            }

            if(this.form.guests.length===0 && !this.byFrontDeskApp) {
                this.$f7.dialog.alert('Podaj liczbę gości');
                return;
            }

            let vue = this;
            let stayId = this.form.stayId;
            this.$store.dispatch('registerStay', {
                data: this.formData(),
                f7: this.$f7
            }).then(function () {
                vue.$f7.popup.close();
                vue.$$('#view-stays-tab').trigger('click');
                vue.$f7.views.get('#view-stays').router.navigate('/stay/'+stayId+'/');
                // reset form :/
                vue.$emit('registered', {uuid: vue.form.stayId});
            });

        },
        setDates(dates) {
            this.form.from = dates[0] ? moment(dates[0]).format('YYYY-MM-DD') : null;
            this.form.to = dates[1] ? moment(dates[1]).format('YYYY-MM-DD'): null;
        }
    },
    components: {
        'guests-every-person': guestsEveryPerson,
        'guests-one-person': guestsOnePerson,
        'guests-front-desk-app': guestsFrontDeskApp,
        'accommodation-select': accommodationSelect
    }
}
</script>

<style>
.country-select .item-inner {
    display: block !important;
}

.country-select .smart-select .item-after {
    max-width: 100%;
    padding-left: 0;
    padding-top: 10px;
}

.country-select .item-inner:before {
    display: none !important;
    content: '';
}


.mode-select .item-inner {
    display: block !important;
}

.mode-select .smart-select .item-after {
    max-width: 100%;
    padding-left: 0;
    padding-top: 10px;
}

.mode-select .item-inner:before {
    /*
    display: none !important;
    content: '';
    */
}

</style>
