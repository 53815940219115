<template>
    <f7-page name="stay">
        <f7-navbar :back-link="masterDetailMode ? null : 'Powrót'">
            <f7-nav-title>{{stay.from|moment('D')}} - {{stay.to|moment('D.MM.YYYY')}}</f7-nav-title>
        </f7-navbar>


        <f7-block v-if="stay.cancelled" style="color: red">
            {{cancelledInfo}}
        </f7-block>

        <f7-block-title>Informacje</f7-block-title>
        <f7-list inline-labels no-hairlines-md inset>
            <f7-list-item title="Numer" :after="stay.number"></f7-list-item>
            <f7-list-item title="Data">
                <div class="item-after">{{stay.from|moment('D.MM.YYYY')}} - {{stay.to|moment('D.MM.YYYY')}}</div>
            </f7-list-item>
        </f7-list>

        <f7-block-title>Rozliczenie</f7-block-title>
        <f7-list inline-labels no-hairlines-md inset>
            <f7-list-item v-if="stay.requested" title="Należność" :after="stay.requested|price"></f7-list-item>
            <f7-list-item title="Pobrano" :after="stay.charged|price"></f7-list-item>
            <f7-list-button @click="chargePayment($event, stay.leftToPay)" v-if="stay.leftToPay" color="red" :class="paymentButtonClass">
                <div class="item-title">Potwierdź pobranie {{stay.leftToPay|price}}</div>
            </f7-list-button>
            <f7-list-button @click="refundPayment($event, stay.leftToRefund)" v-if="stay.leftToRefund" color="green" :class="paymentButtonClass">
                <div class="item-title">Potwierdź zwrot {{stay.leftToRefund|price}}</div>
            </f7-list-button>
            <f7-list-button v-if="stay.hasReceipt" :external="true" :href="receiptUrl" title="Pobierz potwierdzenie"></f7-list-button>
            <f7-list-button v-if="stay.hasReceipt && !sendReceiptPopupOpened" @click="sendReceiptPopupOpened = true" :title="sendReceiptLabel"></f7-list-button>
        </f7-list>

        <form @submit="sendReceipt" v-if="sendReceiptPopupOpened" @popup:closed="sendReceiptPopupOpened = false">
            <f7-block-title>Wysyłanie potwierdzenia na e-mail</f7-block-title>
            <f7-list inline-labels media-list inset>
                <f7-list-input
                    label="E-mail"
                    type="email"
                    validate
                    required
                    placeholder="E-mail"
                    :value="sendReceiptEmail"
                    @input="sendReceiptEmail = $event.target.value"
                ></f7-list-input>
                <f7-list-item class="sendReceipt">
                    <f7-button large type="submit">Wyślij potwierdzenie</f7-button>
                </f7-list-item>
            </f7-list>
        </form>

        <form @submit="sendReceiptSms" v-if="sendReceiptPopupOpened && stay.smsEnabled" @popup:closed="sendReceiptPopupOpened = false">
            <f7-block-title>Wysyłanie potwierdzenia na telefon</f7-block-title>
            <f7-list inline-labels media-list inset>
                <f7-list-input
                    label="Numer telefonu"
                    type="text"
                    validate
                    required
                    placeholder="123 456 789 lub 48 123 456 789"
                    :value="sendReceiptPhone"
                    @input="sendReceiptPhone = $event.target.value"
                ></f7-list-input>
                <f7-list-item class="sendReceipt">
                    <f7-button large type="submit">Wyślij potwierdzenie</f7-button>
                </f7-list-item>
            </f7-list>
        </form>


        <div v-if="stay.hasReceipt && qrCode">
            <f7-block-title>Kupon rabatowy</f7-block-title>
            <f7-list media-list inline-labels no-hairlines-md inset>
                <f7-list-item link="#" @click="qrPopupOpened = true" :title="stay.coupon" after="Powiększ">
                    <img slot="media" :src="qrCode" width="24" />
                </f7-list-item>
            </f7-list>
        </div>

        <f7-popup :opened="qrPopupOpened" @popup:closed="qrPopupOpened = false">
            <f7-page>
                <f7-navbar :title="stay.coupon">
                    <f7-nav-right>
                        <f7-link popup-close>Zamknij</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block class="qrcode">
                    <img :src="qrCode" :alt="stay.coupon" />
                </f7-block>
                <f7-block class="coupon-details">
                    <h1>{{stay.coupon}}</h1>
                    <p>Okres pobytu: <strong>{{stay.from|moment('D.MM.YYYY')}} - {{stay.to|moment('D.MM.YYYY')}}</strong></p>
                    <p>Ilość osób: <strong>{{stay.guests.length}}</strong></p>
                </f7-block>
            </f7-page>
        </f7-popup>


        <div v-if="!stay.bulk">
            <f7-block-title>Lista gości</f7-block-title>
            <f7-list accordion-list inset>
                <f7-list-item
                        accordion-item v-for="(guest, no) in guestPersons" :key="no"
                        :after="tierName(guest.tierId)"
                        :title="guest.name">
                    <f7-accordion-content>
                        <f7-block>
                            <p v-if="guest.email">E-mail: <strong>{{guest.email}}</strong></p>
                            <p v-if="guest.phone">Telefon: <strong>{{guest.phone}}</strong></p>
                        </f7-block>
                    </f7-accordion-content>
                </f7-list-item>
                <f7-list-item
                        v-for="(guest, no) in guestOthers" :key="no"
                        :after="tierName(guest.tierId)"
                        :title="guest.name">
                </f7-list-item>
            </f7-list>
        </div>


        <div v-if="stay.bulk">
            <f7-block-title>Osobodoby</f7-block-title>
            <f7-list inset>
                <f7-list-item v-for="(guests, no) in stay.guests" :key="no" :title="tierName(guests.tierId)" :after="guests.count"></f7-list-item>
            </f7-list>
        </div>






        <f7-block-title v-if="manyAccommodations">Obiekt nocelegowy</f7-block-title>
        <f7-list v-if="manyAccommodations" simple-list inset>
            <f7-list-item :title="stay.accommodation.name"></f7-list-item>
        </f7-list>

        <f7-block-title v-if="!!stay.comments">Komentarze</f7-block-title>
        <f7-block inset v-if="!!stay.comments" strong><p>{{stay.comments}}</p></f7-block>


        <f7-block-title v-if="canChange">Nowa data wyjazdu</f7-block-title>
        <f7-list inset v-if="canChange">
            <f7-list-item :title="changedStayDate | moment('D.MM.YYYY')">
                <f7-stepper :buttons-only="true" slot="after" @stepper:minusclick="decreaseStayLength" @stepper:plusclick="increaseStayLength"></f7-stepper>
            </f7-list-item>
            <f7-list-button @click="updateLeavingDate" :title="changeStayLengthButton"></f7-list-button>
        </f7-list>

        <f7-list inset v-if="!stay.cancelled && !stay.refusal">
            <f7-list-button @click="cancelPrompt" :title="cancelStayButton" color="red"></f7-list-button>
        </f7-list>

        <br>
        <br>
    </f7-page>
</template>
<style>
    .sendReceipt .item-inner {
        padding-top: 0;
        padding-bottom: 0;
    }

    .qrcode {
        text-align: center;
    }

    .qrcode img {
        max-width: 380px;
        width: 100%;
        margin: auto;
    }
    .coupon-details {
        text-align: center;
        padding-top: 10px;
        font-size: 16px;
        color: #000;
    }

</style>
<script>
    import moment from 'moment';
    import qrCode from 'qrcode';
    import uuid4 from 'uuid/v4'

    export default {
        data: function () {
            return {
                stayLengthChange: 0,
                qrCode: null,
                qrPopupOpened: false,
                sendReceiptPopupOpened: false,
                sendReceiptEmail: null,
                sendReceiptPhone: null,
                paymentsDisabled: false,
                paymentId: uuid4(),
                refundId: uuid4(),
            };
        },
        computed: {
            masterDetailMode() {
                return this.$f7.width >= 800;
            },
            stay() {
                let id = this.$f7route.params.id;
                let stay = this.$store.getters.stay(id);

                if(stay.couponQrCode) {
                    qrCode.toDataURL(stay.couponQrCode, {margin: 0, width: 300}).then(url => {
                        this.qrCode = url;
                    });
                } else {
                    this.qrCode = null;
                }

                return stay;
            },
            guestPersons() {
                return this.stay.guests.filter(guest => !!guest.name);
            },
            guestOthers() {
                return this.stay.guests
                    .filter(guest => guest.name===null && guest.pesel===null)
                    .reduce((result, guest) => {
                        if(guest.tierId in result) {
                            result[guest.tierId].count++;
                            if(result[guest.tierId].count===2)
                                result[guest.tierId].name = 'dwie osoby';
                            else if(result[guest.tierId].count===3)
                                result[guest.tierId].name = 'trzy osoby';
                            else if(result[guest.tierId].count===4)
                                result[guest.tierId].name = 'cztery osoby';
                            else
                                result[guest.tierId].name = result[guest.tierId].count + ' osób';
                        } else {
                            result[guest.tierId] = {count: 1, name: 'jedna osoba', tierId: guest.tierId};
                        }
                        return result;
                    }, {});
            },
            receiptUrl: function() {
                return this.$store.state.apiUri + 'stays/' + this.stay.id + '.pdf?token=' + this.$store.state.user.token;
            },
            manyAccommodations: function () {
                return this.$store.getters.manyAccommodations;
            },
            changeStayLengthButton() {
                if(this.stayLengthChange===1) {
                    return 'Przedłuż pobyt o 1 dzień';
                }
                if(this.stayLengthChange>1) {
                    return 'Przedłuż pobyt o ' + this.stayLengthChange + ' dni';
                }

                if(this.stayLengthChange===-1) {
                    return 'Skróć pobyt o 1 dzień';
                }
                if(this.stayLengthChange<-1) {
                    return 'Skróć pobyt o ' + this.stayLengthChange*-1 + ' dni';
                }
            },
            sendReceiptLabel: function () {
                return 'Wyślij potwierdzenie' + (this.stay.smsEnabled ? ' (e-mail, SMS)' : '');
            },
            minChangeStay() {
                const from = moment(this.stay.from);
                const to = moment(this.stay.to);
                return from.diff(to, 'days') + 1;
            },
            changedStayDate() {
                const to = moment(this.stay.to);
                return to.add(this.stayLengthChange, 'days');
            },
            canChange() {
                return !this.stay.bulk && !this.stay.cancelled && !this.stay.refusal;
            },
            paymentButtonClass() {
                return this.paymentsDisabled ? 'disabled' : '';
            },
            cancelStayButton() {
                return this.stay.bulk ? 'Odwołaj wpłatę' : 'Odwołaj pobyt';
            },
            cancelledInfo() {
                if(this.stay.bulk) return 'Uwaga! Ta wpłata jest odwołana.';
                return 'Uwaga! Ten pobyt jest odwołany. Możliwe jest jedynie dokonanie zwrotu pobranych środków.';
            }
        },
        methods: {
            tierName(id) {
                return this.$store.getters.tierName(id) ?? '';
            },
            chargePayment(event, amount) {
                event.preventDefault();
                if(this.paymentsDisabled) {
                    return;
                }
                this.paymentsDisabled = true;
                this.$store.dispatch('chargePayment', {id: this.stay.id, amount: amount, paymentId: this.paymentId, f7: this.$f7})
                    .finally( () => {
                        this.paymentsDisabled = false;
                        this.paymentId = uuid4();
                    });
            },
            refundPayment(event, amount) {
                event.preventDefault();
                if(this.paymentsDisabled) {
                    return;
                }
                this.paymentsDisabled = true;
                this.$store.dispatch('refundPayment', {id: this.stay.id, amount: amount, refundId: this.refundId, f7: this.$f7})
                    .finally( () => {
                        this.paymentsDisabled = false;
                        this.refundId = uuid4();
                    });
            },
            updateLeavingDate(event) {
                event.preventDefault();
                const date = this.changedStayDate.format('YYYY-MM-D');
                let vue = this;
                this.$store.dispatch('updateLeavingDate', {
                    id: this.stay.id,
                    date: date,
                    f7: this.$f7
                })
                    .then( () => vue.stayLengthChange = 0)
                    .catch( (e) => console.log('fail', e));
            },
            increaseStayLength() {
                this.stayLengthChange += 1;
            },
            decreaseStayLength() {
                if(this.stayLengthChange > this.minChangeStay) {
                    this.stayLengthChange -= 1;
                }
            },
            sendReceipt(event) {
                event.preventDefault();
                let vue = this;
                this.$store.dispatch('sendReceipt', {id: this.stay.id, email: this.sendReceiptEmail, f7: this.$f7})
                    .then( () => vue.sendReceiptPopupOpened = false )
                    .catch( (e) => vue.$f7.dialog.alert( e.message ?? 'Wystąpił problem z wysyłką powiadomienia, spróbuj ponownie.') );
            },
            sendReceiptSms(event) {
                event.preventDefault();
                let vue = this;
                this.$store.dispatch('sendReceiptSms', {id: this.stay.id, phone: this.sendReceiptPhone, f7: this.$f7})
                    .then( () => vue.sendReceiptPopupOpened = false )
                    .catch( (e) => vue.$f7.dialog.alert(e.message) );
            },
            cancelPrompt() {
              const message = this.stay.bulk ? 'Podaj powód odwołania wpłaty' : 'Podaj powód odwołania pobytu';
              this.$f7.dialog.prompt(message, 'Uwaga!', this.cancel);
            },
            cancel(reason) {
                if(reason==='') {
                    this.cancelPrompt();
                    return;
                }
                this.$store.dispatch('cancelStay', {
                    id: this.stay.id,
                    reason: reason,
                    f7: this.$f7
                }).catch( (e) => console.log('fail', e));
            },
        },
        mounted() {
            this.sendReceiptEmail = this.stay.guests.reduce(function(carry, guest) {
                if(guest.email) {
                    carry = guest.email;
                }
                return carry;
            }, '');

            this.sendReceiptPhone = this.stay.guests.reduce(function(carry, guest) {
                if(guest.email) {
                    carry = guest.phone;
                }
                return carry;
            }, '');
        }

    };
</script>
